import axios from 'axios';
import TokenService from './token';
export default class AuthClient {

    static url: any = process.env.REACT_APP_AUTH_SERVER_URL;
    static client_id: any = process.env.REACT_APP_AUTH_SERVER_CLIENT_ID

    
    static async getAccessToken(credential: string) {
        try {
            const res = await axios.post(`${this.url}/oauth/token`, {
                assertion: credential,
                grant_type: "assertion",
                provider: 'google',
                client_id: this.client_id
            })
            return res.data
        }
        catch (err) { console.log(err) }
    }

    static async getRefreshToken() {
        try{
      
            const res = await axios.post(`${this.url}/oauth/token`,{
                refresh_token: TokenService.getRefreshToken(),
                grant_type:'refresh_token',
                client_id: this.client_id
            })
            return res.data
        }
        catch(err){console.log(err)}
    }
}