import React from "react";
import { ICompare } from "../types";
import { formatMoney } from "../../../utils/formatMoney";

const totalize = (plan: ICompare, rateType: string, first = false) => {
  let total = plan.rate.main_price[rateType]; // Initializing total
  let couple = plan.rate?.couple_price?.[rateType] || 0;
  let kids = plan.rate?.kids_price?.[rateType] || 0;
  total += couple + kids;
  let costo = plan.rate.riders.find((x) => x.name === "Costo Administrativo");
  if (first) {
    total += costo?.price || 0;
  }
  plan.rate.riders
    .filter((x) => x.name !== "Costo Administrativo")
    .forEach((rider) => {
      if (rateType === "yearly") {
        total += rider.price;
      } else {
        total += rider.biyearly_price;
      }
    });
  return total; // We return total
};

export const PremiumRow: React.FC<{ compare: ICompare[]; rateType: string }> =
  ({ compare, rateType }) => {
    if (rateType === "yearly") {
      return (
        <tr>
          <th></th>
          {compare.map((plan, k) => {
            return (
              <th key={k}>{formatMoney(totalize(plan, rateType, true))}</th>
            );
          })}
        </tr>
      );
    }
    return (
      <>
        <tr>
          <th></th>
          {compare.map((plan, k) => {
            return (
              <th key={k}>
                Primera Cuota: {formatMoney(totalize(plan, rateType, true))}
              </th>
            );
          })}
        </tr>
        <tr>
          <th></th>
          {compare.map((plan, k) => {
            return (
              <th key={k}>
                Segunda Cuota: {formatMoney(totalize(plan, rateType, false))}
              </th>
            );
          })}
        </tr>
      </>
    );
  };
