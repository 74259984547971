import React from "react";
import { ICompare } from "../types";
import { formatMoney } from "../../../utils/formatMoney";
export const Deductible: React.FC<{ plan: ICompare }> = ({ plan }) => {
  return (
    <th>
      <div className="quote-modal-deductible">
        <p>
          {" "}
          {formatMoney(plan.deductible)} /{" "}
          {plan.rate && formatMoney(plan.rate.deductible_out)}
        </p>
      </div>
    </th>
  );
};
