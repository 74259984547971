

export default class TokenService {
  static setAccessToken(token: string) {
    localStorage.setItem('access_token', token)
  }

  static setRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token)
  }

  static setUserData(user: string) {
    localStorage.setItem('user_data', JSON.stringify(user))
  }

  static getAccessToken() {
    return localStorage.getItem('access_token')
  }

  static getRefreshToken() {
    return localStorage.getItem('refresh_token')
  }
  static getUserData() {
    let data = localStorage.getItem('user_data')
    if (data) {
      return JSON.parse(data)
    }
  }
  static deleteItem(name: string) {
    localStorage.removeItem(name)
  }
}

export function setToken(tokenName: string, tokenValue: string) {
  localStorage.setItem(tokenName, tokenValue);
}

export function getToken(tokenName: string) {
  return localStorage.getItem(tokenName);
}
export function removeToken(tokenName: string) {
  localStorage.removeItem(tokenName);
}
