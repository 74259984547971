import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import AuthClient from "./AuthClient";
import TokenService from "./token";

class QuotiClient {
    instance: AxiosInstance;
    constructor() {
        this.instance = Axios.create({ baseURL: process.env.REACT_APP_API_URL })
        this.setupRequestInterceptors();
        this.setupResponseInterceptors();
    }
    setupRequestInterceptors() {
        this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
            const token = TokenService.getAccessToken()
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`
            }
            return config;
        })
    }

    setupResponseInterceptors() {
        this.instance.interceptors.response.use(
            res => res,
            async (err) => {
                const originalConfig = err.config;
                if (err.response) {
                    if (err.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        try {
                            const res = await AuthClient.getRefreshToken()
                            TokenService.setAccessToken(res.access_token)
                            TokenService.setRefreshToken(res.refresh_token)
                            return this.instance(originalConfig);
                        }
                        catch (error: any) {
                            if (error.response && error.response.data) {
                                return Promise.reject(error.response.data)

                            }
                            return Promise.reject(error)
                        }
                    }
                    return Promise.reject(err);
                }
            }
        )

    }

    async getQuote(values: any) {
        let res = this.instance.post("/plans/quote", values)
        return res;
    }



    getPlanNames() {
        let res = this.instance.get("/plans/getNames")
        return res;
    }

    getCompare(plans: any) {
        let res = this.instance.post("/plans/compare", plans);
        return res
    }


}

const QuotiApi = new QuotiClient();
export default QuotiApi;

