import React from "react";
import { ICompare } from "../types";
export const Header: React.FC<{ plan: ICompare; length: number }> = ({
  plan,
  length,
}) => {
  return (
    <th style={{ width: `${100 / (length + 1)}%` }}>
      <div className="quote-modal-header">
        <p> {plan.company}</p>
        <p> {plan.name}</p>
        <p>Cobertura Máxima: {plan.coverage}</p>
      </div>
    </th>
  );
};
