import Axios from "axios";
import React, { useContext, useMemo, useState } from "react";
import { useCallback } from "react";
import AuthClient from '../services/AuthClient';
import TokenService from '../services/token';
import {
  getToken,
  removeToken,
} from "../services/token";

import { APIURL } from "../utils";

export const UserContext = React.createContext<IUserContextType>({
  user: null,
  userActions: null,
  errors: "",
  notification: ""
});

UserContext.displayName = "Users Context";


export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<null | any>(null);
  const [notification, setNotification] = useState("");

  const LoginUser = async (res: any) => {
    let { access_token, refresh_token, user } = await AuthClient.getAccessToken(res.credential);
    if (access_token) {
      TokenService.setAccessToken(access_token)
      TokenService.setRefreshToken(refresh_token)
      TokenService.setUserData(user)
      setUser(user)
    }
  };

  const LogoutUser = useCallback(() => {
    setUser({ ...user });
    removeToken("access_token");
    removeToken("refresh_token");
    removeToken("user_data");
  }, [user]);

  const recoverPassword = useCallback(async (values: { username: string }) => {
    const res = await Axios.post(APIURL + "/users/recover_password", values);
    return res;
  }, []);

  const updatePassword = useCallback(
    async (values: { uid: string; password: string }) => {
      const res = await Axios.put(APIURL + "/users/update_password", values);
      if (!res.data.errors) {
        window.location.href = "/";
      }
    },
    []
  );

  const userActions = useMemo(
    () => ({
      loginUser: (user: IUserLogin) => LoginUser(user),
      logout: () => LogoutUser(),
      recoverPassword,
      updatePassword,
      setNotification
    }),
    [LogoutUser, recoverPassword, updatePassword, setNotification]
  );
  let token = getToken("user_data");
  const value = {
    user: token ? JSON.parse(token) : null,
    userActions,
    errors: "",
    notification
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

interface IUserContextType {
  user?: any;
  userActions?: any;
  errors?: string;
  notification?: string
}

export interface IUserLogin {
  email: string;
  password: string;
}

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};
