import React from "react";
import { Alert, Card, Table } from "react-bootstrap";
import { Loader } from "../../components/Loader";
import { useUser } from "../../context/User";
import { useCompare } from "./context";

export const CompareTable: React.FC = () => {
  const { notification, userActions } = useUser();

  /*
  function changeCategory(categoryName: number) {
    if (category === categoryName) {
      setCategory(0);
    } else {
      setCategory(categoryName);
    }
  }
  */

  const { comparativo, loading } = useCompare();
  const columnWidth = 100 / (1 + (comparativo ? comparativo.length : 0));

  if (comparativo) {

    const benefitNames = comparativo[0].benefits.map(x => x.name)
    return (
      <Card.Body>
        {
          /*
           <ButtonGroup className="w-100 d-none d-xl-block">
          {comparativo?.categories.map((categoryName, index: number) => {
            let active = category === categoryName.id ? "active" : "";
            return (
              <Button
                key={index}
                onClick={() => changeCategory(categoryName.id)}
                className={`tab-button ${active}`}
                size="sm"
              >
                {categoryName.category_name}
              </Button>
            );
          })}
        </ButtonGroup>
        */
        }

        <div style={{ padding: 10 }} className="compare-table-wrapper">
          <Table variant="striped" responsive className="benefits-table">
            <thead>
              <tr>
                <th style={{ width: `${columnWidth}%` }}>Beneficio</th>
                <>
                  {comparativo.map((plan, key: number) => (
                    <th key={key} style={{ width: `${columnWidth}%` }}>
                      {plan.name}
                    </th>
                  ))}
                </>
              </tr>
            </thead>
            <tbody>
              {
                benefitNames.map((name: string, key: number) => (
                  <tr>
                    <th>{name}</th>
                    {
                      comparativo.map((plan, index) => (
                        <td style={{whiteSpace:'pre-line'}}>{plan.benefits.find(x => x.name === name)?.description}</td>
                      ))
                    }
                  </tr>
                ))
              }
              { /* comparativo?.benefits.map((b, key) => {
                if (b.category_id === category || category === 0) {
                  return (
                    <tr key={key}>
                      <th>{b.name}</th>
                      {comparativo.plans.map((plan, index) => (
                        <td>
                          {
                            plan.benefits.find((x) => x.name === b.name)
                              ?.description
                          }
                        </td>
                      ))}
                    </tr>
                  );
                }
                return null;
              }) */}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    );
  }
  if (loading) {
    return <Loader />;
  }
  if (notification) {
    return <Alert dismissible onClose={() => userActions.setNotification("")} variant="danger">{notification}</Alert>
  }
  return null;
};
