import React, { useEffect } from "react";
import { useUser } from "../../context/User";
import { LoginScreen } from "./Screen";
import useScript from '../../utils/useScript';
export const LoginForm: React.FC = () => {
  const { userActions } = useUser();
  const status = useScript('https://accounts.google.com/gsi/client')
  // @ts-ignore
  const google = window.google
  const signIn = async(res:any)=>{
    userActions.loginUser(res)
  }

  useEffect(() => {
   
      // @ts-ignore
      if (status === 'ready') {
          google.accounts.id.initialize({
              client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
              callback: signIn
          })
          // @ts-ignore
          google.accounts.id.prompt()
          // @ts-ignore
          google.accounts.id.renderButton(document.getElementById('google-button'), { theme: 'outline', width:'100%'})
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])


  return (
    <LoginScreen>
      
      {
        /*
      <Form
        onSubmit={onFormSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="username">
              {({ input, meta }) => (
                <FormGroup>
                  <label>Correo Electronico:</label>

                  <RoundInput size="sm" type="email" {...input} />
                </FormGroup>
              )}
            </Field>
            <Field name="password">
              {({ input, meta }) => (
                <FormGroup>
                  <label>Contraseña:</label>
                  <RoundInput size="sm" type="password" {...input} />
                </FormGroup>
              )}
            </Field>

            <FormGroup>
              <RoundButton block type="submit">
                Ingresar
              </RoundButton>
            </FormGroup>
            <Link className="d-block text-center" to="/recover_password">
              Olvido su contraseña?
            </Link>
          </form>
        )}
      ></Form>
        */
      }

    </LoginScreen>
  );
};
