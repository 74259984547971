import React, { useContext, useEffect, useMemo, useState } from "react";
import { useUser } from "../../context/User";
import QuotiApi from "../../services/QuotiClient";
import { IContext } from "./types";

const initialContext = {
  planNames: [],
  compareActions: null,
  comparativo: undefined,
  loading: false,
};

export const CompareContext = React.createContext<IContext>(initialContext);

export const CompareProvider: React.FC = ({ children }) => {
  const { userActions } = useUser();
  const [planNames, setPlanNames] = useState<string[]>([]);
  const [comparativo, setComparativo] = useState();

  const [loading, setLoading] = useState<boolean>(false);
  const getPlanNames = () => {
    
    QuotiApi.getPlanNames()
      .then(res => {
        setPlanNames(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  };

  useEffect(() => {
  
    getPlanNames();
  }, []);


  const getCompare = async (plans: string[]) => {
    setLoading(true);
    QuotiApi.getCompare(plans)
      .then(res => {
        setComparativo(res.data.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {

          userActions.setNotification(err.response.data.details.error)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  };

  const compareActions = useMemo(
    () => ({
      getCompare: (plans: string[]) => getCompare(plans),
      getPlanNames,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  const value = { planNames, compareActions, comparativo, loading };

  return (
    <CompareContext.Provider value={value}>{children}</CompareContext.Provider>
  );
};

export const useCompare = () => {
  const context = useContext(CompareContext);
  return context;
};
