import React from "react";
import { Card } from "react-bootstrap";
import { QuoteProvider, useQuote } from "./context";
import { SideBarScreen } from "../../layouts/SidebarScreen";
import { Sidebar } from "./Sidebar";
import { QuoteTable } from "./Table";
export interface IIndexable {
  [key: string]: string;
}
const CotizadorHOC: React.FC = () => {
  const countries: IIndexable = {
    BO: "Bolivia",
    PY: "Paraguay",
  };

  const { quote } = useQuote();
  return (
    <SideBarScreen Sidebar={Sidebar}>
      <Card className="cotizador-card">
        <Card.Header className="d-flex flex-row justify-content-between align-items-center">
          Cotizacion de Planes
          {quote && (
            <div style={{ fontSize: "0.8em" }}>
              <span className="mr-3">Cotizando:</span>
              <span>
                {countries[quote?.params.country]}, Titular:{" "}
                {quote?.params.main_age}
                {quote?.params.couple_age &&
                  `, Pareja: ${quote?.params.couple_age}`}
                {quote?.params.kids_ages &&
                  `, ${quote?.params.kids_ages.length} Hijos`}
              </span>
            </div>
          )}
        </Card.Header>
        <QuoteTable />
      </Card>
    </SideBarScreen>
  );
};
export const Cotizador: React.FC = () => {
  return (
    <QuoteProvider>
      <CotizadorHOC />
    </QuoteProvider>
  );
};
