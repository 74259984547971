import React, { useState } from "react";
import { Button, ButtonGroup, Modal, Table, Dropdown } from "react-bootstrap";
import { useQuote } from "./context";
import { formatMoney } from "../../utils/formatMoney";
import { Header } from "./components/Header";
import { Deductible } from "./components/Deductible";
import { PremiumRow } from "./components/PremiumRow";

export const QuoteModal: React.FC = () => {
  const [rateType, setRateType] = useState<string>("yearly");
  const [show, setShow] = useState(false);
  const { compare, quoteActions, quote } = useQuote();
  const toggle = (value: boolean) => {
    setShow(value);
  };

  return (
    <>
      <Button onClick={() => toggle(true)} className="btn-block btn-success">
        Ver Comparativo
      </Button>
      <Modal
        className="quote-modal"
        dialogClassName="modal-90w"
        size="xl"
        show={show}
        onHide={() => toggle(false)}
      >
        <Modal.Header closeButton onHide={() => toggle(false)}>
          Comparativo de Planes
        </Modal.Header>
        <Modal.Body>
          <Table size="sm" variant="bordered">
            <thead>
              <tr>
                <th style={{ width: `${100 / (compare.length + 1)}%` }}>
                  <ButtonGroup className="w-100">
                    <Button
                      variant="primary"
                      className={
                        "bg-blue-dark " +
                        (rateType === "yearly" ? "selected" : "")
                      }
                      onClick={() => setRateType("yearly")}
                    >
                      Anual
                    </Button>
                    <Button
                      variant="primary"
                      className={
                        "bg-blue-dark " +
                        (rateType === "biyearly" ? "selected" : "")
                      }
                      onClick={() => setRateType("biyearly")}
                    >
                      Semestral
                    </Button>
                  </ButtonGroup>
                </th>
                {compare.map((plan, k) => (
                  <Header key={k} plan={plan} length={compare.length} />
                ))}
              </tr>

              <tr>
                <th>
                  <p>
                    Deducible Seleccionado <small>(dentro/fuera de USA):</small>
                  </p>
                </th>
                {compare.map((plan, k) => (
                  <Deductible key={k} plan={plan} />
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Titular ({quote?.params.main_age} Años)</th>
                {compare.map((plan, k) => (
                  <th
                    key={k}
                    style={{ width: `${100 / (compare.length + 1)}%` }}
                  >
                    {formatMoney(plan.rate?.main_price[rateType])}
                  </th>
                ))}
              </tr>
              {compare[0].rate.couple_price && (
                <tr>
                  <th>Pareja ({quote?.params.couple_age} Años) </th>
                  {compare.map((plan, k) => (
                    <th
                      key={k}
                      style={{ width: `${100 / (compare.length + 1)}%` }}
                    >
                      {plan.rate?.couple_price !== null
                        ? formatMoney(plan.rate?.couple_price[rateType])
                        : null}
                    </th>
                  ))}
                </tr>
              )}
              {compare[0].rate.kids_price && (
                <tr>
                  <th>Dependientes ({quote?.params.kids_ages.length})</th>
                  {compare.map((plan, k) => (
                    <th
                      key={k}
                      style={{ width: `${100 / (compare.length + 1)}%` }}
                    >
                      {plan.rate?.kids_price !== null
                        ? formatMoney(plan.rate?.kids_price[rateType])
                        : null}
                    </th>
                  ))}
                </tr>
              )}
              <tr className="bg-medium-blue">
                <th></th>
                <th className="text-center" colSpan={999}>
                  ENDOSOS ADICIONALES
                </th>
              </tr>
              <tr>
                <th>Costo Administrativo</th>
                {compare.map((plan, k) => {
                  let rider = plan.rate.riders.find(
                    (x) => x.name === "Costo Administrativo"
                  );
                  return (
                    <th key={k}>
                      {rider?.selected ? formatMoney(rider.price) : "--"}
                    </th>
                  );
                })}
              </tr>
              <tr>
                <th>Complicaciones de Maternidad</th>
                {compare.map((plan, k) => {
                  let rider = plan.rate.riders.find(
                    (x) => x.name === "Complicaciones de Maternidad"
                  );
                  return (
                    <th key={k}>
                      {rider?.selected
                        ? formatMoney(
                            rateType === "yearly"
                              ? rider.price
                              : rider.biyearly_price
                          )
                        : "--"}
                    </th>
                  );
                })}
              </tr>
              <tr>
                <th>Transplante de Organos</th>
                {compare.map((plan, k) => {
                  let rider = plan.rate.riders.find(
                    (x) => x.name === "Transplante de Órganos"
                  );
                  return (
                    <th key={k}>
                      {rider?.selected
                        ? formatMoney(
                            rateType === "yearly"
                              ? rider.price
                              : rider.biyearly_price
                          )
                        : "--"}
                    </th>
                  );
                })}
              </tr>
              <tr className="bg-medium-blue">
                <th></th>
                <th className="text-center" colSpan={999}>
                  PRIMA TOTAL {rateType === "yearly" ? "ANUAL" : "SEMESTRAL"}
                </th>
              </tr>
            </tbody>
            <tfoot>
              <PremiumRow compare={compare} rateType={rateType} />
            </tfoot>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Dropdown>
            <Dropdown.Toggle>Descargar</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Button
                  variant="link"
                  onClick={() => quoteActions.exportQuote(rateType)}
                >
                  Solo Tarifas
                </Button>
              </Dropdown.Item>
              <Dropdown.Item>
                <Button variant="link">Tarifas + Beneficios</Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Button as='a' href={pdf.url||"#"} download='test.pdf' variant='success'>Descargar (PDF)</Button>*/}
          <Button variant="danger" onClick={() => toggle(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
